@import url('./assets/icomoon/style.css');
@import '~typeface-roboto/index.css';
@import '~typeface-work-sans/index.css';

body {
  margin: 0;
  font-family: Work Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

code {
  font-family: Work Sans, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

span {
  font-weight: normal;
}
