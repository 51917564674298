@import 'styles/breakpoints';
@import 'styles/colors';

.mainSection {
  background: url('../../assets/values/main_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  overflow: hidden;
  background-color: $main-grey;
  .content {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 3rem;
    padding: 5%;
    min-height: 90vh;
    text-align: center;
    justify-content: center;

    .subtitle {
      color: $white;
      font-size: 18px;
      // letter-spacing: 14.4px;
      line-height: 3.44;
    }

    .subtitleTwo {
      @extend .subtitle;
      padding-top: 15px;
    }
    b {
      color: $main-orange;
    }
    .image {
      width: 70%;
      align-self: center;
    }
    .title {
      color: $main-orange;
      font-size: 52px;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 1.19;
      padding-top: 15px;
      white-space: pre-line;
      align-self: center;
    }

    .text {
      align-self: center;
      color: $white;
      font-size: 17px;
      line-height: 1.47;
      padding-top: 40px;
    }
    .downloadButton {
      align-self: center;
      display: flex;
      margin-top: 75px;
    }
  }
}

@media screen and (max-width: $screen-md-min) {
  .mainSection {
    background-position: right;
  }
  .mainSection .content {
    .subtitle {
      font-size: 12px;
    }

    .title {
      font-size: 24px;
      padding-top: 25px;
    }

    .text {
      font-size: 13px;
      padding-top: 30px;
    }

    .downloadButton {
      margin-top: 45px;
    }
  }
}
