// http://chir.ag/projects/name-that-color/

$alto: #dbdbdb;
$black: #000000;
$concrete: #f2f2f2;
$dark-orange: #df5600;
$heavy-metal: #20211e;
$hot-toddy: #b3860c;
$light-aqua: #51fff5;
$light-silver: #c9c9c9;
$ligher-silver: rgba(206, 206, 206, 0.7);
$main-aqua: #00b3a7;
$main-cyan: #00fff3;
$main-grey: #2e2e2e;
$main-dark: #22221f;
$main-orange: #e0a513;
$mine-shaft: #3b3b3b;
$silver: #c6c6c6;
$white: #ffffff;
